import './Projects.css';
import Project from './Project';
import weatherImg from './assets/people.png';
import onepageImg from './assets/onepage-template.webp';
import portalImg from './assets/people.png';
import bergenImg from './assets/people.png';
import steveImg from './assets/stevepert-website.webp';
import strengImg from './assets/strengmc-website.webp';

const Projects = () => {
    return (
        <div className="projects section">
            <h2>Portfolio</h2>
            <div className="projects--container">
                <Project
                    title = "Architectural Render"
                    description = "When is dinner best served? At 6:30 sharp, of course! This is a website I made for a local restaurant in my area."
                    image = {portalImg}
                    imagealt = "Portal main page screenshot"
                    buttons = {[
                        {text: "Laugh at it :)", link: "https://www.youtube.com/watch/../%6C%6F%67%6F%75%74", id: "1"}
                    ]}
                    key = "1"
                />
                <Project
                    title = "Architectural Render"
                    description = "Sometimes life is like a box of chocolates, you never know what you're gonna get."
                    image = {bergenImg}
                    imagealt = "Image of Bergen"
                    buttons = {[
                        {text: "Laugh at it :D", link: "http://www.nobrain.dk", id: "1"}
                    ]}
                    key = "2"
                />
                <Project
                    title = "Architectural Render"
                    description = "Bum like a Japanese flag, I'm on the rise. Do you like my website? I made it myself."
                    image = {weatherImg}
                    imagealt = "Example of weather type recognition model input image"
                    buttons = {[
                        {text: "Laugh at it ;)", link: "https://www.pornhub.com/view_video.php?viewkey=6643dc35d48a5", id: "1"}
                    ]}
                    key = "3"
                />
            </div>
        </div>
    );
}

export default Projects;