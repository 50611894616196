import './Contact.css';

const Contact = () => {
    return (
        <div className="contact section">
            <h2>Contact Me</h2>
            <p>Send me an email at <span>willt3004@gmail.com</span></p>
        </div>
    );
}

export default Contact;