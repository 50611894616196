import './About.css';
import billyImg from './assets/james.png';

const About = ({ age }) => {
    return (
        <div className="about section">
            <h2>About Me</h2>
            <div className="about--section">
                <img className="profile-picture" alt="Billy sitting on a bike and smiling awkwardly" src={billyImg} />
                <div className="about--text">
                    <h3>Hello there!</h3>
                    <p>I'm James. I'm a { age } year old professional graduate, having recently graduated from The Oxford <p style={{fontSize: "10px", display: "inline"}}>Brookes</p> University.</p>
                    <p>On this page are a few of my portfolio projects, feel free to check them out.</p>
                    <br />
                    <p>My LinkedIn profile is available at <a href="https://www.youtube.com/watch?v=1Qp_jo-JAAc" target="_blank" rel="noreferrer">https://www.linkedin.com/in/james-lowery/</a>.</p>
                </div>
            </div>
        </div>
    );
}

export default About;