import './Hero.css';
import ballsImg from './assets/balls.svg';

const Hero = () => {
    return (
        <div className="hero section">
            <img src={ballsImg} alt="Glow" />
            <div className="hero--content">
                <h1>
                    James'
                    <span className="hero--highlight">Portfolio</span>
                </h1>
                <p>A collection of my university work</p>
            </div>
        </div>
    );
}

export default Hero;